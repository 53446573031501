//==============================================================================
// This module is part 2 of helping restore catalog id. Combined with module catalog-cookie.
//
// Microsoft relies on URLs to keep track of the current catalog, which is fragile.
// Links to My Account and hard-coded or relative internal links will cause
// the catalog ID to be dropped.
//
// This module  uses the saved cookie from the catalog-cookie module to repair links
// used even if the URL isn't set.
//==============================================================================
import * as React from 'react';

import { ICatalogLinkRepairerData } from './catalog-link-repairer.data';
import { ICatalogLinkRepairerProps } from './catalog-link-repairer.props.autogenerated';

//==============================================================================
// CONSTANTS
//==============================================================================
const COOKIE_NAME = 'd365_catalog';

//==============================================================================
// CLASS
//==============================================================================
class CatalogLinkRepairer extends React.PureComponent<ICatalogLinkRepairerProps<ICatalogLinkRepairerData>> {

    //----------------------------------------------------------
    //----------------------------------------------------------
    public componentDidMount(): void {

        // TEMPORARY DISABLE
        const shutoff = true;
        if (shutoff) {
            return;
        }

        // Fix links that were generated before catalogManagement set the catalog
        // This isn't terribly reliable since links get generated at various times.
        // We can't fix links that don't appear until they are scrolled into view in this manner.
        // Another approach would be to add the catalog ID to the URL if it's not currently present, but that might not
        // do anything unless there is a page reload, which would be bad.
        const repairLinks = this.repairLinks.bind(this);

        // Defer the first execution to allow all first-pass render actions to complete
        setTimeout(repairLinks, 0);

        // Repeat the fix after a little while to allow (some) REST-based renders to complete
        setTimeout(repairLinks, 1500);
    }

    //----------------------------------------------------------
    // This module doesn't display anything, but render() is required
    //----------------------------------------------------------
    public render(): JSX.Element | null {
        return null;
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private repairLinks() {

        const context = this.props.context.request;
        const urlId = context.query && context.query.catalogid;
        const cookieId = context.cookies.get<string>(COOKIE_NAME);

        // If a catalog is set in the URL, there is no need for adjustments.
        // If a cookie with the catalog ID isn't set, there's nothing we can do.
        if (!urlId && cookieId.value) {

            // Not ideal React code. We should't be messing with the DOM directly. But this is a special case.
            const allLinks = Array.from(document.getElementsByTagName('a'));

            // Walk through all links
            allLinks.forEach(link => {

                // Parse the search query into a useful format
                const href = link.getAttribute('href');
                const query = href?.match(/^[^?#]+\?([^#]+)/);  // [full_url, query_string] | null
                const parsedQueries = Array.isArray(query) && new URLSearchParams(query[1]);

                // Check for a catalogid
                if (parsedQueries && parsedQueries.get('catalogid')) {

                    // Update the catalogid from the cookie
                    parsedQueries.set('catalogid', cookieId.value!);
                    const newHref = href!.replace(query![1], parsedQueries.toString());
                    link.setAttribute('href', newHref);
                }
            });
        }
    }
}

export default CatalogLinkRepairer;
